import React, { useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import Textarea from "react-autosize-textarea";

import { withRouter, useHistory } from "react-router-dom";
import {
  clearPassageForm,
  newPassageForm,
  newPassageFormFromPassage,
  onUpdatePassage,
  onUpdatePassageForm,
  commitPassageForm,
  onDeletePassage,
} from "../actions/passage.actions";

const PassageFormView = ({
  passage,
  passageForm = {},
  openModal,
  editMode,
  onDeletePassage = () => {},
  newPassageFormFromPassage,
  newPassageForm,
  commitPassageForm,
  onUpdatePassageForm = () => {},
}) => {
  const history = useHistory();
  useEffect(() => {
    editMode && passage ? newPassageFormFromPassage(passage) : newPassageForm();
    return () => {
      clearPassageForm();
    };
  }, [editMode, passage, newPassageForm, newPassageFormFromPassage]);
  return (
    <Modal
      classNames={{ modal: "w-full h-auto" }}
      open={openModal}
      closeOnEsc={true}
      onClose={() => {
        history.goBack();
      }}
    >
      <div className="w-full mt-6 h-auto">
        <form className="flex flex-col w-full justify-between">
          <div className="justify-start">
            <Textarea
              className="w-full resize border rounded mt-2 mb-2 focus:outline-none focus:shadow-outline"
              style={{ minHeight: 150, maxHeight: 600 }}
              placeholder="Please Type or Paste Your Passage Here."
              value={passageForm.content}
              rows={10}
              maxRows={30}
              onChange={(e) =>
                onUpdatePassageForm({
                  ...passageForm,
                  content: e.currentTarget.value,
                })
              }
            />
            <div className="w-full flex my-2 mb-4">
              <div className="w-1/2">
                <label htmlFor="Level">Organize Passages by Level: </label>
                <select
                  value={parseInt(passageForm.level)}
                  defaultValue={1}
                  onChange={(e) => {
                    onUpdatePassageForm({
                      ...passageForm,
                      level: parseInt(e.currentTarget.value),
                    });
                  }}
                >
                  {Array(10)
                    .fill(1)
                    .map((v, i) => {
                      const level = i + 1;
                      return <option value={level}>Level {level}</option>;
                    })}
                </select>
              </div>
              <div className="w-1/2"></div>
            </div>
          </div>

          <div className="flex flex-row">
            {passage && passageForm && (
              <button
                className="bg-red-500 hover:bg-red-700 text-white mr-auto font-bold py-2 px-4 rounded-full"
                onClick={(e) => {
                  e.preventDefault();
                  if (window.confirm("Are you sure?")) {
                    onDeletePassage(passage);
                    history.push("/passages");
                  }
                }}
              >
                Delete
              </button>
            )}
            <div className="ml-auto text-center">
              <button
                className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded-full"
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                Cancel
              </button>
            </div>
            <div className="ml-4 text-center">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                onClick={() => {
                  commitPassageForm(passageForm);
                  history.push("/passages");
                }}
              >
                Save Passage
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default withRouter(
  connect(
    (state, { match: { params } }) => {
      const passageId = params.passageId;
      const { passages, passageForm } = state.passageState;
      const passage = passages.find((p) => p.id === passageId);
      return {
        passage: passageId ? passage : undefined,
        passageForm,
      };
    },
    {
      onUpdatePassage,
      onUpdatePassageForm,
      newPassageFormFromPassage,
      clearPassageForm,
      onDeletePassage,
      newPassageForm,
      commitPassageForm,
    }
  )(PassageFormView)
);
