const initialState = {
  showSidebar: true
};

const handler = {
  APP_SET_SIDEBAR: (state, action) => {
    const { showSidebar } = action;
    return { ...state, showSidebar };
  }
};

export default (state = initialState, action) => {
  const fn = handler[action.type];
  if (fn) return fn(state, action);
  return state;
};
