import React from "react";
import {
  IdentityContextProvider,
  useIdentityContext
} from "react-netlify-identity";
import { connect } from "react-redux";
import { Route, Switch, NavLink, Link } from "react-router-dom";
import { setShowSidebar } from "./actions/app.actions";
import CurrentStudent from "./components/CurrentStudent";
import MainFooter from "./components/MainFooter";
import MainNavHeader from "./components/MainNavHeader";
import StudentListView from "./components/StudentListView";
import StudentDetailView from "./components/StudentDetailView";
import PassageListView from "./components/PassageListView";
import PassageView from "./components/PassageView";

// supply the url of your Netlify site instance with Identity enabled. VERY IMPORTANT
const NETLIFY_IDENTITY_URL = "https://oral.readingfluency.app";

const LoginView = () => {
  const { loginProvider, isLoggedIn } = useIdentityContext();

  return (
    <div
      className={`fixed flex w-full h-full z-20 ${!isLoggedIn ? "hidden" : ""}`}
    >
      <div className="absolute w-full h-full bg-teal-900"></div>
      <div className="self-stretch m-auto h-1/5 z-10 px-6">
        <button
          className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded-full"
          onClick={() => {
            loginProvider("google");
          }}
        >
          Login with Google
        </button>
        <p className="text-gray-500 mx-auto mt-8 pl-1">
          To Login to Fluency, you have to be invited. Email:
          <br />{" "}
          <a
            className="text-teal-500"
            href="mailto:support@theidealistspace.com"
          >
            support@theidealistspace.com
          </a>{" "}
          to be invited.
        </p>
      </div>
    </div>
  );
};

const Sidebar = ({ showSidebar, children }) => (
  <div
    className={`flex flex-col relative bg-indigo-100 overflow-auto ${
      showSidebar ? "w-1/4" : "hidden"
    }`}
  >
    {children}
  </div>
);

const MainContent = ({ children }) => (
  <div className="flex-1 flex flex-col relative">{children}</div>
);

const AllPassagesNav = () => (
  <NavLink
    to="/passages"
    className="px-4 text-white h-full flex justify-between items-center hover:bg-indigo-600"
    activeClassName="bg-teal-600"
  >
    <div className="opacity-75 w-full">All Passages</div>
    <Link to="/passages/new">
      <svg
        className="fill-current h-4 w-4 opacity-50"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"></path>
      </svg>
    </Link>
  </NavLink>
);

const App = ({ showSidebar }) => {
  return (
    <IdentityContextProvider url={NETLIFY_IDENTITY_URL}>
      <LoginView />
      <div className="text-gray-800 antialiased h-screen flex">
        <Sidebar showSidebar={showSidebar}>
          <div
            className={`${
              showSidebar ? "w-1/4" : "hidden"
            } fixed h-10 bg-teal-700`}
          >
            <AllPassagesNav />
          </div>
          <div className="my-10 overflow-y-scroll flex-1 bg-indigo-900">
            <StudentListView />
          </div>
          <div
            className={`${
              showSidebar ? "w-1/4" : "hidden"
            } fixed h-10 bottom-0 flex items-center bg-indigo-600 text-white`}
          >
            <CurrentStudent />
          </div>
        </Sidebar>
        <MainContent>
          <div
            className={`${
              showSidebar ? "w-3/4" : "w-full"
            } fixed bg-gray-200 h-10`}
          >
            <MainNavHeader />
          </div>
          <div className="w-full my-10 overflow-y-scroll">
            <Switch>
              <Route path="/s/:studentId" component={StudentDetailView} />
              <Route path="/passages/:passageId" component={PassageView} />
              <Route path="/" component={PassageListView} />
            </Switch>
          </div>
          <div className={`${showSidebar ? "w-3/4" : "w-full"} fixed bottom-0`}>
            <MainFooter />
          </div>
        </MainContent>
      </div>
    </IdentityContextProvider>
  );
};

export default connect(
  state => {
    const { showSidebar } = state.appState;
    return {
      showSidebar
    };
  },
  {
    setShowSidebar
  }
)(App);
