import { generateGuid } from "./reducer_helper";

const ALL_TESTS_KEY = "SW_ALL_TESTS_KEY";

const storedTests = localStorage.getItem(ALL_TESTS_KEY);
let allTests;
try {
  allTests = (storedTests && JSON.parse(storedTests)) || [];
} catch (err) {
  allTests = [];
}

const commitState = (state) => {
  localStorage.setItem(ALL_TESTS_KEY, JSON.stringify(state));
};

const initialTest = {
  startIndex: 0,
  endIndex: 0,
  markedItems: [],
};

const initialState = {
  tests: allTests,
  test: undefined,
  testResult: {},
  testForm: undefined,
};

const handler = {
  TEST_NEW: (state, action) => {
    const {
      existingTest = {},
      parsedPassage,
      passage = {},
      student = {},
    } = action;
    const length = parsedPassage.length;

    const span = [0, length - 2]; // minus 2 because last element is always linebreak.
    const startIndex = Math.min(...span);
    const endIndex = Math.max(...span);
    const test = {
      ...initialTest,
      startIndex,
      endIndex,
      passageId: passage.id,
      studentId: student.id,
      ...existingTest,
      isUpdated: !existingTest.id, //if has existingTest, mark it as isUpdated=false by default
    };

    const currentSpan = parsedPassage.slice(test.startIndex, test.endIndex + 1);
    const totalCount = currentSpan.filter((x) => x.countable).length;
    const incorrectCount = test.markedItems.filter(
      (x) => x <= test.endIndex && x >= test.startIndex
    ).length;
    const correctCount = totalCount - incorrectCount;
    const scoreInPercent = totalCount
      ? Math.floor((correctCount / totalCount) * 100)
      : 0;
    return {
      ...state,
      test,
      testResult: {
        incorrectCount,
        correctCount,
        totalCount,
        scoreInPercent,
      },
    };
  },
  TEST_ITEM_MARK: (state, action) => {
    const { itemIndex } = action;
    const { test, testResult } = state;
    const { startIndex, endIndex, markedItems: existingItems } = test;
    let markedItems = [...existingItems];

    const foundIndex = markedItems.indexOf(itemIndex);
    if (foundIndex > -1) {
      markedItems.splice(foundIndex, 1);
    } else {
      markedItems.push(itemIndex);
    }
    const { totalCount } = testResult;
    const incorrectCount = markedItems.filter(
      (x) => x <= endIndex && x >= startIndex
    ).length;
    const correctCount = totalCount - incorrectCount;
    const scoreInPercent = totalCount
      ? Math.floor((correctCount / totalCount) * 100)
      : 0;

    const newTestResult = {
      ...testResult,
      incorrectCount,
      correctCount,
      scoreInPercent,
    };
    const newTest = { ...test, markedItems, isUpdated: true };
    return {
      ...state,
      test: newTest,
      testResult: newTestResult,
    };
  },
  TEST_UPDATE: (state, action) => {
    const { test, testResult } = state;
    const { startIndex, endIndex, parsedPassage } = action;
    const { markedItems } = test;
    const currentSpan = parsedPassage.slice(startIndex, endIndex + 1);
    const totalCount = currentSpan.filter((x) => x.countable).length;
    const incorrectCount = markedItems.filter(
      (x) => x <= endIndex && x >= startIndex
    ).length;
    const correctCount = totalCount - incorrectCount;
    const scoreInPercent = totalCount
      ? Math.floor((correctCount / totalCount) * 100)
      : 0;

    const newTestResult = {
      ...testResult,
      totalCount,
      correctCount,
      incorrectCount,
      scoreInPercent,
    };
    const newTest = {
      ...test,
      startIndex,
      endIndex,
      isUpdated: true,
    };

    return {
      ...state,
      test: newTest,
      testResult: newTestResult,
    };
  },
  TEST_RECORD: (state, action) => {
    const { tests } = state;
    const { test, testResult, student } = action;

    let newTests;
    if (test.id) {
      newTests = tests.map((t) => {
        if (t.id !== test.id) return t;
        return { ...t, ...test, testResult };
      });
    } else {
      newTests = [
        ...tests,
        {
          id: generateGuid(),
          createdAt: new Date(),
          studentId: student.id,
          ...test,
          testResult,
          isUpdated: false,
        },
      ];
    }
    commitState(newTests);
    return {
      ...state,
      tests: newTests,
    };
  },
  TEST_CLEAR: (state, action) => {
    return {
      ...state,
      test: undefined,
      testResult: undefined,
    };
  },
};

export default (state = initialState, action) => {
  const fn = handler[action.type];
  if (fn) return fn(state, action);
  return state;
};
