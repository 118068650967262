import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { setShowSidebar } from "../actions/app.actions";
import { recordTest } from "../actions/test.actions";
import Wind from "../assets/icon";

const MainFooter = ({
  test,
  testResult = {},
  setShowSidebar,
  showSidebar,
  recordTest,
  student,
}) => {
  const {
    incorrectCount,
    correctCount,
    totalCount,
    scoreInPercent,
  } = testResult;
  const [buttonLabel, setButtonLabel] = useState("Save Changes");
  return (
    <div className="px-4 flex bg-gray-200 justify-center items-center overflow-hidden">
      <div className="mr-auto">
        <button
          className="mt-2"
          onClick={() => {
            setShowSidebar(!showSidebar);
          }}
        >
          <Wind />
        </button>
      </div>
      {testResult && test && (
        <Fragment>
          <h1 className="text-2xl mx-auto">
            (
            <span className="text-red-700" aria-label="Incorrect Count">
              {incorrectCount}
            </span>
            +
            <span className="text-green-700" aria-label="Correct Count">
              {correctCount}
            </span>
            )/
            {totalCount} | {scoreInPercent}%
          </h1>
          <div className="ml-auto"></div>
        </Fragment>
      )}
      {test && student && testResult && (
        <button
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold ml-2 py-0 px-4 rounded-full ${
            test?.isUpdated ? "" : "cursor-not-allowed opacity-50"
          }`}
          onClick={() => {
            setButtonLabel("Saving...");
            recordTest(test, testResult, student);
            setButtonLabel("Saved");
            setTimeout(() => setButtonLabel("Save Changes"), 1000);
          }}
        >
          {buttonLabel}
        </button>
      )}
    </div>
  );
};

export default connect(
  (state) => {
    const { test, testResult } = state.testState;
    const { showSidebar } = state.appState;
    const { student } = state.studentState;
    return { showSidebar, testResult, test, student };
  },
  { setShowSidebar, recordTest }
)(MainFooter);
