import { generateGuid, parsePassage } from "./reducer_helper";

const ALL_PASSAGES_KEY = "SW_ALL_PASSAGES_KEY";

const storedPassages = localStorage.getItem(ALL_PASSAGES_KEY);
let allPassages;
try {
  allPassages = (storedPassages && JSON.parse(storedPassages)) || [];
} catch (err) {
  allPassages = [];
}

const commitState = (state) => {
  localStorage.setItem(ALL_PASSAGES_KEY, JSON.stringify(state));
};

const initialState = {
  passages: allPassages,
  passage: undefined,
  parsedPassage: undefined,
  passageForm: undefined,
  listLevel: "all",
};

const handler = {
  PASSAGE_UPDATE: (state, action) => {
    const { passages } = state;
    const newPassages = passages.map((p) => {
      if (p.id === action.passageForm.id) return { ...action.passageForm };
      return p;
    });
    commitState(newPassages);
    return { ...state, passages: newPassages };
  },
  PASSAGE_DELETE: (state, action) => {
    const { passages } = state;
    const { passage } = action;
    const newPassages = passages.filter((p) => p.id !== passage.id);
    commitState(newPassages);
    return { ...state, passages: newPassages };
  },
  PASSAGE_FORM_CREATE: (state, _action) => {
    return {
      ...state,
      passageForm: { id: generateGuid(), content: "", createdAt: new Date() },
    };
  },
  PASSAGE_FORM_NEW_FROM_PASSAGE: (state, action) => {
    const { passage } = action;
    return { ...state, passageForm: { ...passage } };
  },
  PASSAGE_FORM_NEW: (state, action) => {
    return {
      ...state,
      passageForm: { content: "", level: 1 },
    };
  },
  PASSAGE_FORM_COMMIT: (state, action) => {
    const { passageForm } = action;
    const { passages } = state;
    passageForm.content = passageForm.content.trim();
    let newPassages;
    if (passageForm.id) {
      newPassages = state.passages.map((p) => {
        if (p.id === passageForm.id) return passageForm;
        return p;
      });
    } else {
      newPassages = [
        ...passages,
        { ...passageForm, id: generateGuid(), createdAt: new Date() },
      ];
    }
    commitState(newPassages);
    return { ...state, passages: newPassages };
  },
  PASSAGE_FORM_CLEAR: (state, _action) => {
    return { ...state, passageForm: undefined };
  },
  PASSAGE_FORM_UPDATE: (state, action) => {
    const { passageForm } = action;
    return { ...state, passageForm: { ...passageForm } };
  },
  PASSAGE_LIST_LEVEL_UPDATE: (state, action) => {
    const { listLevel } = action;
    return { ...state, listLevel };
  },
  PASSAGE_SET: (state, action) => {
    const { passage } = action;
    const parsedPassage = parsePassage(passage?.content || "");
    return { ...state, passage, parsedPassage };
  },
  PASSAGE_CLEAR: (state, action) => {
    return { ...state, passage: undefined, parsedPassage: undefined };
  },
};

export default (state = initialState, action) => {
  const fn = handler[action.type];
  if (fn) return fn(state, action);
  return state;
};
