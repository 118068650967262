import React from "react";
import { connect } from "react-redux";
import { Route, Link } from "react-router-dom";
import { setStudent, clearStudent } from "../actions/student.actions";
import StudentFormView from "./StudentFormView";

const CurrentStudent = ({ student }) => {
  return (
    <div className="mx-4 w-full">
      {student && (
        <div className="flex flex-row items-center align-middle">
          <div className="mr-auto">{student.name}</div>
          <div className="ml-auto">
            <Route
              path={`/s/:studentId/edit`}
              render={({ match }) => {
                return <StudentFormView editMode openModal={match.isExact} />;
              }}
            ></Route>
            <Link to={`/s/${student.id}/edit`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
                fill={"white"}
              >
                <path
                  className="heroicon-ui"
                  d="M6.3 12.3l10-10a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H7a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h6a1 1 0 0 1 0 2H4v14h14v-6z"
                />
              </svg>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(
  state => {
    const { student } = state.studentState;
    return { student };
  },
  {
    setStudent,
    clearStudent
  }
)(CurrentStudent);
