import React from "react";
import { connect } from "react-redux";
import { NavLink, Route, Link } from "react-router-dom";
import StudentFormView from "./StudentFormView";

const StudentListView = ({ students, passage }) => {
  return (
    <div>
      <Route
        path={`/s/new`}
        render={({ match }) => {
          return <StudentFormView openModal={match.isExact} />;
        }}
      ></Route>
      <div className="h-full text-purple-lighter flex-none pt-4">
        <div className="px-4 text-white flex justify-between items-center">
          <div className="opacity-75">All Students</div>
          <Link to="/s/new">
            <svg
              className="fill-current h-4 w-4 opacity-50"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"></path>
            </svg>
          </Link>
        </div>
        {students.map((s, i) => {
          return (
            <NavLink
              className="block px-4 w-full text-white hover:bg-indigo-600"
              activeClassName="bg-teal-600"
              to={
                passage
                  ? `/s/${s.id}/passages/${passage.id}`
                  : `/s/${s.id}/passages`
              }
              key={i}
            >
              {s.name}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default connect((state, _ownProps) => {
  const { passage } = state.passageState;
  const { students } = state.studentState;
  return { students, passage };
})(StudentListView);
