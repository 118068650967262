import { combineReducers } from "redux";
import appState from "./app.state";
import testState from "./test.state";

import passageState from "./passage.state";
import studentState from "./student.state";

export default combineReducers({
  passageState,
  studentState,
  appState,
  testState
});
