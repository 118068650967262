export const newTest = (existingTest, passage, student, parsedPassage) => ({
  type: "TEST_NEW",
  existingTest,
  passage,
  student,
  parsedPassage,
});

export const markTestItem = (itemIndex) => ({
  type: "TEST_ITEM_MARK",
  itemIndex,
});

export const onTestUpdate = (startIndex, endIndex, parsedPassage) => ({
  type: "TEST_UPDATE",
  startIndex,
  endIndex,
  parsedPassage,
});

export const clearTest = (test) => ({
  type: "TEST_CLEAR",
  test,
});

export const recordTest = (test, testResult, student) => ({
  type: "TEST_RECORD",
  test,
  testResult,
  student,
});

export const onDeleteTest = (test) => ({
  type: "TEST_DELETE",
  test,
});
