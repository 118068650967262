import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const PassageCard = ({ passage, student, studentTest }) => {
  return (
    <Fragment>
      <Link
        className="bg-teal-100 h-40 clamp-3 rounded-md flex relative hover:shadow"
        to={
          student
            ? `/s/${student.id}/passages/${passage.id}`
            : `/passages/${passage.id}`
        }
      >
        <p className="p-4">{passage.content}</p>
        <div className="flex absolute text-white bottom-0 w-full bg-teal-400 h-8 px-4 py-1">
          <div className="mr-auto inline-block">
            Level: {passage.level || 1}
          </div>
          {student && studentTest?.testResult && (
            <div className="inline-block">
              Score: {studentTest.testResult.scoreInPercent}%
            </div>
          )}
        </div>
      </Link>
    </Fragment>
  );
};

const PassageListView = ({ passages, student, studentTests }) => {
  return (
    <div className="mb-20">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
        {passages.map((p, i) => {
          const studentTest =
            student &&
            studentTests.length > 0 &&
            studentTests.find(
              (t) => t.studentId === student.id && t.passageId === p.id
            );
          return (
            <PassageCard passage={p} key={i} {...{ student, studentTest }} />
          );
        })}
      </div>
    </div>
  );
};

export default connect((state) => {
  const { passages, listLevel } = state.passageState;
  const filteredPassage =
    listLevel === "all"
      ? passages
      : // eslint-disable-next-line
        passages.filter((p) => p.level == listLevel);
  const { student } = state.studentState;
  const studentTests = student
    ? state.testState.tests.filter((t) => t.studentId === student.id)
    : [];
  return {
    passages: filteredPassage,
    student,
    studentTests,
  };
})(PassageListView);
