import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useParams, Route, Switch, useRouteMatch } from "react-router-dom";
import { setStudent, clearStudent } from "../actions/student.actions";
import PassageListView from "./PassageListView";
import PassageView from "./PassageView";

const StudentDetailView = ({ student, setStudent, clearStudent }) => {
  const { studentId } = useParams();
  const match = useRouteMatch();
  useEffect(() => {
    studentId && setStudent(studentId);
    return () => {
      clearStudent();
    };
  }, [studentId, setStudent, clearStudent]);
  return (
    <Fragment>
      <Route
        path={`${match.path}/passages`}
        render={({ match }) => {
          if (match.isExact) return <PassageListView {...{ student }} />;
          return (
            <Switch>
              <Route path={`${match.path}/:passageId`}>
                <PassageView />
              </Route>
            </Switch>
          );
        }}
      />
    </Fragment>
  );
};

export default connect(
  state => {
    const { student } = state.studentState;
    return { student };
  },
  {
    setStudent,
    clearStudent
  }
)(StudentDetailView);
