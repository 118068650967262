export const onUpdatePassage = (passage, passageForm) => ({
  type: "PASSAGE_UPDATE",
  passageForm
});

export const onUpdatePassageForm = passageForm => ({
  type: "PASSAGE_FORM_UPDATE",
  passageForm
});

export const onUpdatePassageListLevel = listLevel => ({
  type: "PASSAGE_LIST_LEVEL_UPDATE",
  listLevel
});

export const setPassage = passage => ({
  type: "PASSAGE_SET",
  passage
});

export const clearPassage = passage => ({
  type: "PASSAGE_CLEAR",
  passage
});

export const onDeletePassage = passage => ({
  type: "PASSAGE_DELETE",
  passage
});

export const newPassageFormFromPassage = passage => ({
  type: "PASSAGE_FORM_NEW_FROM_PASSAGE",
  passage
});

export const newPassageForm = () => ({
  type: "PASSAGE_FORM_NEW"
});

export const commitPassageForm = passageForm => ({
  type: "PASSAGE_FORM_COMMIT",
  passageForm
});

export const clearPassageForm = () => ({
  type: "PASSAGE_FORM_CLEAR"
});
