import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link, Route } from "react-router-dom";
import {
  commitPassageForm,
  onDeletePassage,
  onUpdatePassageListLevel,
} from "../actions/passage.actions";
import PassageFormView from "./PassageFormView";

const MainNavHeader = ({
  student,
  passage,
  listLevel,
  onUpdatePassageListLevel,
}) => {
  return (
    <Fragment>
      <div className="relative fixed ml-1/4 h-10 w-full flex flex-row items-center text-dark top-0">
        {!passage && (
          <div className="mr-auto ml-4 text-center">
            <select
              value={listLevel}
              onChange={(e) => {
                onUpdatePassageListLevel(e.currentTarget.value);
              }}
            >
              <option value="all">All Levels</option>
              {Array(10)
                .fill(1)
                .map((v, i) => {
                  const level = i + 1;
                  return <option value={level}>Level {level}</option>;
                })}
            </select>
          </div>
        )}
        {passage && (
          <div className="mx-auto text-center">
            <Link to={student ? `/s/${student.id}/passages/` : `/passages/`}>
              All
            </Link>
          </div>
        )}
        {!passage && (
          <div className="ml-auto mr-4">
            <Route
              exact
              path="/passages/new"
              render={({ match }) => {
                return (
                  <PassageFormView editMode={false} openModal={match.isExact} />
                );
              }}
            />
            <Link to={`/passages/new`}>New</Link>
          </div>
        )}
        {passage && (
          <div className="mr-4">
            <Route
              exact
              path={`/passages/:passageId/edit`}
              render={({ match }) => {
                return (
                  <PassageFormView editMode={true} openModal={match.isExact} />
                );
              }}
            />
            <Link to={`/passages/${passage.id}/edit`}>Edit</Link>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default connect(
  (state) => {
    const { passage, passageForm, listLevel } = state.passageState;
    const { student } = state.studentState;
    return { student, passage, passageForm, listLevel };
  },
  { onDeletePassage, commitPassageForm, onUpdatePassageListLevel }
)(MainNavHeader);
