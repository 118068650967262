export const onUpdateStudent = (student, studentForm) => ({
  type: "STUDENT_UPDATE",
  studentForm,
});

export const onUpdateStudentForm = (studentForm) => ({
  type: "STUDENT_FORM_UPDATE",
  studentForm,
});

export const setStudent = (studentId) => ({
  type: "STUDENT_SET",
  studentId,
});

export const clearStudent = (passage) => ({
  type: "STUDENT_CLEAR",
});

export const deleteStudent = (student) => ({
  type: "STUDENT_DELETE",
  student,
});

export const newStudentForm = (studentForm = {}) => ({
  type: "STUDENT_FORM_NEW",
  studentForm,
});

export const updateStudentForm = (studentForm) => ({
  type: "STUDENT_FORM_UPDATE",
  studentForm,
});

export const clearStudentForm = () => ({
  type: "STUDENT_FORM_CLEAR",
});

export const commitStudentForm = (studentForm) => ({
  type: "STUDENT_FORM_COMMIT",
  studentForm,
});
