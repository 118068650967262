import { generateGuid } from "./reducer_helper";

const ALL_STUDENTS_KEY = "SW_ALL_STUDENTS_KEY";

const stored = localStorage.getItem(ALL_STUDENTS_KEY);
let allRecords;
try {
  allRecords = (stored && JSON.parse(stored)) || [];
} catch (err) {
  allRecords = [];
}

const commitState = (state) => {
  localStorage.setItem(ALL_STUDENTS_KEY, JSON.stringify(state));
};

const initialState = {
  students: allRecords,
  student: undefined,
  studentForm: undefined,
};

const handler = {
  STUDENT_UPDATE: (state, action) => {
    const { students } = state;
    const newStudents = students.map((p) => {
      if (p.id === action.studentForm.id) return { ...action.studentForm };
      return p;
    });
    return { ...state, students: newStudents };
  },
  STUDENT_SET: (state, action) => {
    const { students } = state;
    const student = students.find((s) => s.id === action.studentId);
    return { ...state, student: { ...student } };
  },
  STUDENT_CLEAR: (state, _action) => {
    return { ...state, student: undefined };
  },
  STUDENT_DELETE: (state, action) => {
    const { students } = state;
    const { student } = action;
    const newStudents = students.filter((s) => s.id !== student.id);
    commitState(newStudents);
    return { ...state, students: newStudents };
  },
  STUDENT_FORM_NEW: (state, action) => {
    return { ...state, studentForm: { ...action.studentForm } };
  },
  STUDENT_FORM_UPDATE: (state, action) => {
    const { studentForm } = state;
    return { ...state, studentForm: { ...studentForm, ...action.studentForm } };
  },
  STUDENT_FORM_CLEAR: (state) => {
    return { ...state, studentForm: undefined };
  },
  STUDENT_FORM_COMMIT: (state, action) => {
    const { students } = state;
    const { studentForm } = action;
    let newStudents;
    if (studentForm.id) {
      newStudents = students.map((s) => {
        if (s.id === studentForm.id) return { ...s, ...studentForm };
        return s;
      });
    } else {
      newStudents = [
        ...students,
        { id: generateGuid(), createdAt: new Date(), ...studentForm },
      ];
    }
    commitState(newStudents);
    return { ...state, students: newStudents };
  },
};

export default (state = initialState, action) => {
  const fn = handler[action.type];
  if (fn) return fn(state, action);
  return state;
};
