import React from "react";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
import {
  deleteStudent,
  newStudentForm,
  updateStudentForm,
  clearStudentForm,
  commitStudentForm,
} from "../actions/student.actions";
import { useHistory } from "react-router-dom";

const StudentFormView = ({
  student,
  openModal,
  editMode,
  studentForm = {},
  newStudentForm,
  deleteStudent,
  updateStudentForm,
  clearStudentForm,
  commitStudentForm,
}) => {
  const history = useHistory();
  const { name } = studentForm;
  const closeModal = () => history.goBack();
  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      onEntered={() => {
        editMode ? newStudentForm(student) : newStudentForm();
      }}
      onExited={() => {
        clearStudentForm();
      }}
      showCloseIcon={false}
      center
    >
      <div className="w-full max-w-md">
        <form className="w-full max-w-md">
          <div className="flex items-center border-b-2 border-indigo-500 py-2">
            <input
              className="appearance-none bg-transparent border-none w-full text-gray-700 mx-20 py-2 px-4 leading-tight focus:outline-none"
              type="text"
              value={name || ""}
              onChange={(e) =>
                updateStudentForm({ name: e.currentTarget.value })
              }
              placeholder="Student Name or ID"
              aria-label="Full name"
            />
          </div>
          <div className="flex flex-row mt-4">
            {editMode && (
              <div className="mr-auto">
                <button
                  className="bg-red-500 hover:bg-red-700 text-white mr-auto font-bold py-1 px-4 rounded-full"
                  type="button"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this student and all their saved progress? This action is irreversible."
                      )
                    ) {
                      deleteStudent(student);
                      history.push("/passages");
                    }
                  }}
                >
                  Delete
                </button>
              </div>
            )}
            <div className="flex flex-row ml-auto">
              <button
                className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-4 border border-blue-500 hover:border-transparent rounded-full"
                type="button"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold ml-2 py-2 px-4 rounded-full"
                type="button"
                onClick={() => {
                  commitStudentForm(studentForm);
                  closeModal();
                }}
              >
                {editMode ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default connect(
  (state, _ownProps) => {
    const { student, studentForm } = state.studentState;
    return { student, studentForm };
  },
  {
    deleteStudent,
    newStudentForm,
    updateStudentForm,
    clearStudentForm,
    commitStudentForm,
  }
)(StudentFormView);
