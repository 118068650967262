import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, Prompt } from "react-router-dom";
import { clearPassage, setPassage } from "../actions/passage.actions";
import {
  clearTest,
  markTestItem,
  newTest,
  onTestUpdate,
} from "../actions/test.actions";

const PassageView = ({
  passages,
  passage,
  existingTest,
  test = {},
  parsedPassage,
  student,
  setPassage,
  clearPassage,
  clearTest,
  onTestUpdate,
  newTest,
  markTestItem,
}) => {
  const { passageId } = useParams();
  const [span, setSpan] = useState([0, 0]);
  const { startIndex, endIndex, markedItems } = test;
  const hasPassage = !!parsedPassage;
  useEffect(() => {
    const passage = passages.find((p) => p.id === passageId);
    setPassage(passage);
    return () => {
      clearPassage();
    };
  }, [passageId, setPassage, clearPassage, passages]);

  useEffect(() => {
    parsedPassage && newTest(existingTest, passage, student, parsedPassage);
    return () => {
      parsedPassage && clearTest();
    };
  }, [
    hasPassage,
    parsedPassage,
    existingTest,
    student,
    passage,
    newTest,
    clearTest,
  ]);

  if (!parsedPassage) return <div>Loading passage</div>;
  return (
    <Fragment>
      {student && (
        <Prompt
          when={test?.isUpdated}
          message="Are you sure you want to leave an updated test?"
        />
      )}
      <div className="px-6 mb-20 flex-1 overflow-y-scroll">
        <p className="text-gray-600 mt-3 text-3xl leading-relaxed">
          {parsedPassage.map((passageItem, itemIndex) => {
            if (passageItem.type === "character") {
              const { key, char, countable } = passageItem;
              const marked = markedItems && markedItems.includes(itemIndex);
              const withinRange =
                startIndex <= itemIndex && itemIndex <= endIndex;
              return (
                <button
                  key={key}
                  className={`${marked ? "text-red-600" : ""} ${
                    startIndex === itemIndex ? "border-b-2	border-green-600" : ""
                  } ${
                    endIndex === itemIndex ? "border-b-2	border-red-600" : ""
                  } ${withinRange ? "" : "opacity-50"}`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (!countable) return;
                    markTestItem(itemIndex);
                  }}
                  onDoubleClick={(e) => {
                    e.preventDefault();
                    const newSpan = [span[1], itemIndex];
                    setSpan(newSpan);
                    onTestUpdate(
                      Math.min(...newSpan),
                      Math.max(...newSpan),
                      parsedPassage
                    );
                  }}
                >
                  {char.match(/(\s+)$/) ? <span>&nbsp;&nbsp;</span> : char}
                </button>
              );
            }
            return <br key={passageItem.key} />;
          })}
        </p>
      </div>
    </Fragment>
  );
};

export default connect(
  (state) => {
    const { passages, passage, parsedPassage } = state.passageState;
    const { student } = state.studentState;
    const { tests, test } = state.testState;
    let existingTest;
    if (student && passage) {
      existingTest = tests.find(
        (t) => t.studentId === student.id && t.passageId === passage.id
      );
    }
    return {
      existingTest,
      test,
      passages,
      passage,
      parsedPassage,
      student,
    };
  },
  {
    setPassage,
    newTest,
    clearTest,
    clearPassage,
    onTestUpdate,
    markTestItem,
  }
)(PassageView);
